.c-loading__svg {
  width: 2.5rem;
  height: fit-content;
}

.c-loading {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  height: fit-content;
  width: fit-content;
}
