.c-card-property__badges,
.c-card-property__badge {
  display: flex;
}

.c-card-property__badges > .c-card-property__badge {
  margin: 0 1rem;
}

.c-card-property__badges > .c-card-property__badge:first-of-type {
  margin-left: 0;
}

.c-card-property__badges > .c-card-property__badge:last-of-type {
  margin-right: 0;
}

@media only screen and (max-width: 768px) {

  .c-card-property__badges {
    flex-flow: wrap;
    margin: -0.5rem;
  }

  .c-card-property__badges > .c-card-property__badge,
  .c-card-property__badges > .c-card-property__badge:first-of-type,
  .c-card-property__badges > .c-card-property__badge:last-of-type {
    margin: 0.5rem;
  }
}
