.c-presentation {
  display: flex;
  height: 40rem;
  justify-content: space-between;
  margin: 13rem 0 7rem 6rem;
}

.c-presentation img {
  border-radius: 50%;
  width: 400px;
  height: 400px;
}

.c-presentation__separator {
  width: 0.75rem;
  background-color: var(--primary-color);
  margin: 0 8rem;
}

.c-presentation__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.c-presentation__content-title {
  font-size: 1.75rem;
}

.c-presentation__content-bottom {
  width: 40%;
  display: flex;
  justify-content: space-between;
}

.c-presentation__content-bottom label {
  font-size: 1.125rem;
}

.c-presentation__content-description {
  line-height: 1.65;
  letter-spacing: 1px;
  margin-bottom: 20%;
  width: 80%;
}

.c-presentation__content-description label {
  display: block;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 1366px) {
  .c-presentation {
    margin: 10rem 0 7rem 4rem;
    height: 30rem;
  }

  .c-presentation img {
    width: 300px;
    height: 300px;
  }

  .c-presentation__separator {
    margin: 0 6rem;
  }

  .c-presentation__content-description {
    margin-bottom: 10%;
  }
}

@media only screen and (max-width: 768px) {
  .c-presentation {
    flex-direction: column;
    margin: 7rem 0 2rem 0;
    height: 43rem;
    align-items: center;
  }

  .c-presentation img {
    width: 200px;
    height: 200px;
    margin-bottom: 4rem;
  }

  .c-presentation__separator {
    margin: 0 6rem;
  }

  .c-presentation__content {
    margin-left: 1rem;
  }

  .c-presentation__content-description {
    margin-bottom: 10%;
    width: 100%;
  }

  .c-presentation__content-bottom {
    width: 85%;
  }

  .c-presentation__separator {
    display: none;
  }

  .c-presentation__content-title {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
  }

  .c-presentation__content-description {
    font-size: 0.875rem;
  }

  .c-presentation__content-bottom label {
    font-size: 1rem;
  }
}

