@font-face {
  font-family: Nunito-Bold;
  src: url('../fonts/Nunito-Bold.ttf');
}

@font-face {
  font-family: 'Nunito-Light';
  src: url('../fonts/Nunito-Light.ttf');
}

@font-face {
  font-family: 'Nunito-Medium';
  src: url('../fonts/Nunito-Medium.ttf');
}

@font-face {
  font-family: 'Nunito-Regular';
  src: url('../fonts/Nunito-Regular.ttf');
}

@font-face {
  font-family: 'Nunito-SemiBold';
  src: url('../fonts/Nunito-SemiBold.ttf');
}

/* #bb9132 */

:root {
  --primary-color: #d3a63d;

  --color-default-45: #bb9232;
  --color-default-35: #C28E13;
  --color-default-30: #FFBB1E;
  --color-default-25: #FFD573;

  --color-white: #f9f9f9;

  --color-error-message: #ec0000;

  --color-border: #DDDDDD;

  --color-text: #333333;
  --color-secondary: #777777;

  --color-advice-red: #FF0000;

  --color-border-input: rgba(0, 0, 0, 0.23);

  --font-bold: 'Nunito-Bold';
  --font-light: 'Nunito-Light';
  --font-medium: 'Nunito-Medium';
  --font-regular: 'Nunito-Regular';
  --font-semi-bold: 'Nunito-SemiBold';
}

.d-flex {
  display: flex;
}

/* Element sizing */
.col-1 {
  width: 8.33%;
}

.col-2 {
  width: 16.6%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.33%;
}

.col-5 {
  width: 41.67%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.33%;
}

.col-8 {
  width: 66.67%;
}

.col-9 {
  width: 75%
}

.col-10 {
  width: 83.33%;
}

.col-11 {
  width: 91.67%;
}

.col-12 {
  width: 100%
}

/* Input sizing */
.input__col-1 {
  width: 7%;
}

.input__col-2 {
  width: 15%;
}

.input__col-3 {
  width: 24%;
}

.input__col-4 {
  width: 32%;
}

.input__col-5 {
  width: 40%;
}

.input__col-6 {
  width: 49%;
}

.input__col-7 {
  width: 57%;
}

.input__col-8 {
  width: 65%;
}

.input__col-9 {
  width: 74%
}

.input__col-10 {
  width: 82%;
}

.input__col-11 {
  width: 90%;
}

.input__col-12 {
  width: 100%
}
