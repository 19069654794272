.c-combo-box {
  display: flex;
  justify-content: space-between;
}

.c-combo-box__values {
  display: flex;
}

.c-combo-box__values input {
  display: none;
}

.c-combo-box__value {
  border: 1px solid var(--color-border);
  padding: 0.25rem 1rem;
  display: flex;
  line-height: 1;
  border-radius: 1rem;
  color: var(--color-text);
  cursor: pointer;
  font-size: 0.85rem;
}

.c-combo-box__value.is-selected {
  border: 1px solid var(--color-default-25);
}

.c-combo-box__value:nth-child(2n) {
  margin: 0 0.70rem;
}

.c-combo-box__label {
  font-size: 0.85rem;
}
