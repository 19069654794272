.c-detailed-client__section-title,
.c-detailed-client__title {
  font-family: var(--font-semi-bold);
}

.c-detailed-client__section-title {
  margin: 1.5rem 0;
  display: block;
}

.c-detailed-client__section-title:first-of-type {
  margin-top: 0;
}

.c-detailed-client__title {
  width: 100%;
  font-size: 1.115rem;
}

.c-detailed-client__info {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.c-detailed-client .c-loading {
  position: absolute;
  top: 38%;
  left: calc(50% - 2rem);
}

.c-detailed-client__files .c-button .c-loading {
  position: unset;
}

.c-detailed-client {
  display: flex;
  justify-content: space-between;
}

.c-detailed-client__files button {
  float: right;
}

.c-detailed-client section:first-of-type {
  width: 55%;
}

.c-detailed-client section:last-of-type {
  width: 45%;
}

.c-detailed-client__property {
  display: flex;
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  height: 2.5rem;
  padding: 0.75rem 1rem 0.75rem 1.75rem;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0.75rem 0;
}

.c-detailed-client__property:first-of-type {
  margin-top: 0;
}

.c-detailed-client__property:last-of-type {
  margin-bottom: 0;
}

.c-detailed-client__mode-sell::before,
.c-detailed-client__mode-rent::before {
  content: '';
  border-radius: 5px 0 0 5px;
  width: 10px;
  height: 4rem;
  position: absolute;
  left: 0;
  border: 1px solid black;
}

.c-detailed-client__mode-sell::before {
  background-color: #89D790;
  border-color: #89D790;
}

.c-detailed-client__mode-rent::before {
  background-color: #F8E74F;
  border-color: #F8E74F;
}

.c-detailed__title {
  width: 10rem;
  max-width: 10rem;
  min-width: 10rem;
  display: block;
  overflow: hidden;
}

.c-detailed-client__address {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
}

.c-detailed-client__address svg {
  margin-right: 0.5rem;
  min-width: 1rem;
}

.c-detailed-client__no-content {
  margin-bottom: 2rem;
  width: 100%;
}

@media only screen and (max-width: 1366px) {
  .c-detailed-client .c-loading {
    position: absolute;
    top: 38%;
    left: calc(50% - 2rem);
  }
}

@media only screen and (max-width: 768px) {
  .c-detailed-client {
    flex-direction: column;
  }

  .c-detailed-client section {
    width: 100% !important;
  }

  .c-detailed-client__info {
    width: 100%;
  }

  .c-detailed-client__property {
    height: 2rem;
    padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  }

  .c-detailed-client__mode-sell::before,
  .c-detailed-client__mode-rent::before {
    height:	3rem;
  }

  .c-detailed-client__no-content {
    width: 100%;
  }

  .c-detailed-client__no-content:last-of-type {
    margin-bottom: 0;
  }
}
