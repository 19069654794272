.c-form-layout__sections {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.c-form-layout__title {
  font-family: var(--font-semi-bold);
  font-size: 1.15rem;
}

.c-form-layout__section {
  display: flex;
  flex-wrap: wrap;
  width: 49%;                   /* 1% of margin between contents */
  height: fit-content;
  place-content: space-between;
  overflow-x: auto;
  overflow-y: hidden;
}

.c-form-layout__input {
  margin: 0.5rem 0;
}

.c-form__form-actions-margin {
  margin-top: 0rem;
}

@media only screen and (max-width: 1100px) {
  .c-form-layout__sections {
    display: block;
  }

  .c-form-layout__section {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .c-form__form-actions-margin {
    margin-top: 3rem;
  }  
}
