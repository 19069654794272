.c-checkbox {
  display: flex;
  align-items: center;
}

.c-checkbox svg {
  fill: var(--primary-color);
  padding: 0;
}

@media only screen and (max-width: 768px) {
  .c-checkbox span {
    padding: 0.375rem;
  }
  .c-checkbox {
    font-size: 0.875rem;
  }
}
