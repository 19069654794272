.c-layout {
  min-height: calc(100% - 15.625rem - 9rem);
  padding: 2rem;
}

@media only screen and (max-width: 1366px) {
  .c-layout {
    padding: 0 2rem;
  }
}

@media only screen and (max-width: 600px) {
  .c-layout {
    padding: 2rem 1.5rem;
    min-height: calc(100% - 10rem);
  }
}
