.c-form-actions {
  display: flex;
  position: relative;
  right: 0;
  justify-content: flex-end;
  gap: 2rem;
}

@media only screen and (max-width: 600px) {
  .c-form-actions {
    width: 100%;
    justify-content: space-evenly;
  }
}
