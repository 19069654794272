.c-detailed-property {
  display: flex;
}

.c-detailed-property__property-info {
  width: 70%;
}

.c-detailed-property__property-info__image-box {
  max-width: 90%;
  min-height: 60%;
  width: 90%;
  height: 60%;
  height: fit-content;
}

.c-detailed-property__contact {
  width: 30rem;
}

.c-detailed-property__property-info__image-box img {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.c-detailed-property__content {
  display: flex;
  flex-direction: column;
  max-width: 90%;
  min-width: 90%;
  width: 90%;
}

.c-detailed-property__content-title {
  font-size: 1rem;
  font-family: var(--font-semi-bold);
  margin: 0 0 1.75rem;
}

.c-detailed-property__content-address,
.c-detailed-property__content-description {
  font-size: 0.85rem;
  margin-bottom: 2rem;
  color: var(--color-secondary);
  display: flex;
  align-items: center;
}

.c-detailed-property__content-address svg {
  fill: var(--color-secondary);
  margin-right: 0.5rem;
}

.c-detailed-property__content .c-info-badge {
  width: 5.025rem;
}

.c-detailed-property__info-badge {
  flex-flow: wrap;
  display: flex;
  margin: 0 -2rem;
  height: 9rem;
}

.c-detailed-property__info-badge .c-info-badge__with-label {
  margin: 0 2rem;
}

.c-detailed-property .c-loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45rem;
}

.c-admin-buttons {
  width: 25.875rem;
  display: flex;
  justify-content: flex-end;
  margin-top: 3rem;
}

.carousel .slider-wrapper,
.carousel .slider-wrapper .slider.animated {
  max-height: 35rem;
}

.carousel .slider-wrapper .slider.animated img {
  width: auto;
}

.carousel .carousel-status {
  right: 1rem;
  font-size: 0.875rem;
}

.carousel .thumbs {
  display: flex !important;
  justify-content: center !important;
  padding: 0 !important;
}

.c-detailed-property__carousel .thumb {
  min-width: 80px;
}

.c-admin-buttons button:last-of-type {
  margin-left: 2rem;
}

.c-detailed-property__delete,
.c-detailed-property__edit {
  position: unset;
}

@media only screen and (max-width: 1366px) {
  .c-detailed-property .c-loading {
    height: 32rem;
  }

  .c-detailed-property__property-info {
    width: 65%;
  }

  .c-detailed-property__property-info__image-box {
    max-width: 95%;
    min-width: 95%;
    width: 95%;
  }

  .c-detailed-property__contact {
    width: 23rem;
  }

  .c-admin-buttons {
    width: 22.875rem;
  }
}

@media only screen and (max-width: 768px) {
  .c-detailed-property {
    flex-direction: column;
  }

  .c-detailed-property__property-info {
    width: 100%;
  }

  .c-detailed-property__content {
    width: 100%;
    max-width: 100%;
  }

  .c-detailed-property__property-info__image-box {
    max-width: 100%;
    width: 100%;
  }

  .c-detailed-property__content-title {
    margin: 1rem 0 1rem;
  }

  .c-detailed-property__content-address {
    margin-bottom: 2rem;
  }

  .c-detailed-property__info-badge {
    height: 13rem;
    width: 100%;
    margin: 1rem -1.8rem 0;
  }

  .c-detailed-property__contact {
    width: 100%;
  }

  .c-detailed-property__info-badge .c-info-badge__with-label {
    margin: 0 1.8rem;
  }

  .c-admin-buttons {
    width: 100%;
    justify-content: space-evenly;
  }
}
