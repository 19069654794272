.c-card-property {
  height: 13.5rem;
  display: flex;
  border: 1px solid #DDDDDD;
  margin: 1.5rem 0;
  background-color: #fff;
}

.c-card-property:first-of-type {
  margin-top: 0;
}

.c-card-property:last-of-type {
  margin-bottom: 0;
}

.c-card-property__content {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.c-card-property__content label {
  display: block;
}

.c-card-property__title {
  font-size: 1.15rem;
  font-family: var(--font-semi-bold);
}

.c-card-property__address {
  display: flex;
}

.c-card-property__button-section {
  width: fit-content;
  min-width: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  align-items: flex-end;
}

.c-card-property__price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-secondary);
}

.c-card__vertical .c-card-property__address .c-info-badge {
  place-items: center;
}

.c-card__vertical .c-card-property__address .c-info-badge span,
.c-card__vertical .c-card-property__content .c-info-badge span,
.c-card__vertical .c-card-property__price {
  font-size: 0.813rem;
}

.c-card__vertical .c-card-property__price label {
  font-size: 1rem;
  font-family: var(--font-semi-bold);
  color: var(--color-text);
}

.c-card-property__price label,
.c-card-property__address .c-info-badge span,
.c-card-property__address .c-info-badge svg path {
  color: var(--color-text);
  fill: var(--color-secondary);
}

.c-card__vertical .c-card-property__address .c-info-badge svg {
  width: 0.75rem;
}

/* Vertical mode */
.c-card__vertical {
  flex-direction: column;
  width: 17rem;
  min-width: 17rem;
  max-width: 17rem;
  height: fit-content;
  margin: 0 1rem;
}

.c-card__vertical:first-of-type {
  margin-left: 0;
}

.c-card__vertical:last-of-type {
  margin-right: 0;
}

.c-card__vertical .c-card-property__content {
  width: unset;
  height: 10rem;
}

.c-card__vertical .c-card-property__content .c-info-badge svg {
  width: 1.25rem;
}

.c-card__vertical .c-card-property__address .c-info-badge svg {
  width: 0.65rem;
}

.c-card__vertical .c-card-property__button-section {
  position: relative;
  width: 100%;
  padding: 0;
  height: 2rem;
}

.c-card__vertical .c-card-property__button-section .c-button {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-radius: 0;
  height: 2rem;
  justify-content: space-between;
  text-transform: lowercase;
  font-size: 0.775rem;
  line-height: 0.5;
}

.c-card__vertical .c-card-property__button-section .c-button .MuiButton-endIcon svg {
  width: 0.575rem;
  height: 0.575rem;
}

.c-card__vertical .c-card-property__button-section .c-badge {
  position: absolute;
  top: -20rem;
  right: 1rem;
}

.c-card__vertical .c-card-property__title {
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-card__vertical .c-card-property__address,
.c-card__vertical .c-card-property__description {
  font-size: 0.875rem;
}

.c-card-property__description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.c-card__vertical .c-card-property__badges {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-card__vertical .c-card-property__badges .c-card-property__badge {
  margin: 0;
}

.c-card__vertical img {
  height: 9rem;
  object-fit: cover;
  object-position: center;
}

@media only screen and (max-width: 768px) {
  .c-property-list .c-card-property__price label {
    font-size: 0.835rem;
    font-family: var(--font-semi-bold);
    color: var(--color-default-30);
  }

  .c-card__vertical .c-card-property__description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .c-card__vertical .c-card-property__content {
    height: 8rem;
  }

  .c-card__vertical {
    width: 15.5rem;
    min-width: 15.5rem;
    max-width: 15.5rem;
  }
}
