.c-label {
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
}

.c-label__title {
  color: var(--color-secondary);
  font-size: 0.785rem;
  text-transform: capitalize;
}

.c-label__value {
  font-size: 1rem;
}

@media only screen and (max-width: 768px) {
  .c-label__value {
    font-size: 0.875rem;
  }  
}
