.p-home {
  padding: 0 1rem;
}

.p-home .c-property-list {
  display: flex;
  overflow-y: auto;
  padding-bottom: 1.5rem;
}

.p-home .c-property-list:first-of-type {
  padding-bottom: 1rem;
  width: 73%;
  height: 30rem;
  min-height: 30rem;
}

.p-home .p-home__layout .c-filter-box {
  width: 25%;
  min-width: 25%;
}

.p-home .c-property-slider__property .c-property-slider__box .c-card__vertical {
  width: 14.5rem;
  max-width: 14.5rem;
}

.p-home .c-property-list::-webkit-scrollbar {
  width: 0.45rem;
}

.p-home .c-property-list .c-card__vertical {
  margin: 0 2rem !important;
}

.p-home .c-property-list .c-card__vertical:first-of-type {
  margin-left: 0 !important;
}

.p-home .c-property-list .c-card__vertical:last-of-type {
  margin-right: 0 !important;
}

.p-home .c-property-slider__property .c-card__vertical {
  margin: 0 !important;
}

.p-home .p-home__layout {
  display: flex;
  justify-content: space-between;
}

.p-home .c-filter-box__form {
  height: 29rem;
  min-height: 29rem;
  min-width: 17.5rem;
}

.p-home .c-filter-box__form .c-filter-box__button {
  margin-top: 4rem;
}

.c-filter-box__slider-title {
  width: 100%;
}

.p-home .c-filter-box__slider-price {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 1366px) {
  .p-home .c-card-property__description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .p-home .c-property-slider__steps {
    width: 28%;
  }

  .p-home .c-property-slider__steps .c-property-slider__step-description {
    -webkit-line-clamp: 1;
  }

  .p-home .c-card__vertical .c-property-list .c-card-property__content {
    height: 8rem;
  }

  .p-home .c-property-slider__step-description,
  .p-home .c-property-slider__step-address {
    font-size: 0.85rem;
  }
  
  .p-home .c-property-slider__step-address-box svg {
    width: 0.635rem;
  }

  .p-home .c-property-slider__box .c-card-property__description {
    display: none;
  }

  .p-home .c-property-slider__box .c-card-property__content {
    height: 12rem;
  }

  .p-home .c-property-slider__box .c-card__vertical {
    width: 14rem;
    min-width: unset;
  }

  .p-home .c-property-slider__box .c-card-property__title {
    font-size: 0.875rem;
  }
  
  .p-home .c-property-slider__box .c-card-property__content .c-info-badge span,
  .p-home .c-property-slider__box .c-card-property__price,
  .p-home .c-property-slider__box .c-card-property__price label  {
    font-size: 0.75rem;
  }

  .p-home .c-property-slider__box .c-card-property__address {
    margin-bottom: 0.5rem;
  }

  .p-home .c-property-list:first-of-type {
    width: 73%;
    height: 24rem;
    min-height: unset;
  }

  .p-home .p-home__layout .c-filter-box,
  .p-home .p-home__layout .c-filter-box .c-filter-box__form {
    height: 23rem;
    min-height: 23rem;
  }

  .p-home .p-home__layout .c-filter-box {
    width: 21rem;
    min-width: 21rem;
  }
  
  .p-home .p-home__layout .c-filter-box .c-filter-box__form {
    padding: 0.5rem 1.5rem;
    min-width: unset;
  }

  .p-home .p-home__layout .c-filter-box .c-filter-box__header .c-filter-box__title {
    text-align: center;
    margin: 1rem 0 1rem;
    font-size: 1rem;
  }
  
  .p-home .p-home__layout .c-filter-box .c-filter-box__advice {
    margin-bottom: 0.5rem;
  }
    
  .p-home .p-home__layout .c-filter-box .c-filter-box__slider-price {
    margin-top: 0.75rem;
    font-size: 0.875rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .p-home .p-home__layout .c-filter-box .c-filter-box__slider-price .c-price-slider .MuiSlider-valueLabel {
    font-size: 0.8rem;
  }
  
  .p-home .p-home__layout .c-filter-box .c-filter-box__button {
    font-size: 0.8rem;
    margin-top: 3rem;
  }

  .p-home .c-property-list .c-no-content {
    margin-top: 25%;
    margin-bottom: 10%;
  }

  .p-home .c-property-list .c-card__vertical {
    margin: 0 1rem !important;
  }
}

@media only screen and (max-width: 768px) {
  .p-home {
    padding: 0;
  }

  .p-home .p-home__layout {
    display: block;
  }
  
  .p-home .c-property-list:first-of-type {
    width: 100%;
    height: 20rem;
  }
  
  .p-home .c-filter-box__form {
    height: unset;
    min-height: unset;
    min-width: unset;
    padding: 0.5rem 1.5rem;
  }
  
  .p-home .c-filter-box {
    position: initial;
    width: 100%;
    min-width: unset;
  }

  .p-home .c-filter-box__form .c-filter-box__button {
    margin: 3rem 0 2rem 0;
  }

  .p-home .c-property-list {
    display: flex;
    overflow-y: auto;
    padding-bottom: 1.5rem;
    margin-top: 1rem;
  }

  .p-home .c-property-list .c-no-content {
    margin-top: 50%;
    margin-bottom: 0;
  }
  
  .p-home .c-property-list .c-card__vertical {
    margin: 0 1rem !important;
  }

  .p-home .c-property-list .c-card__vertical:first-of-type {
    margin-left: 0 !important;
  }
  
  .p-home .c-property-list .c-card__vertical:last-of-type {
    margin-right: 0 !important;
  }
  
  .p-home .c-card__vertical .c-card-property__button-section .c-badge {
    top: -18rem;
  }

  .p-home .p-home__layout .c-filter-box {
    width: 100%;
    height: fit-content;
    min-height: unset;
  }

  .p-home .p-home__layout .c-filter-box,
  .p-home .p-home__layout .c-filter-box .c-filter-box__form {
    height: 19rem;
    min-height: 19rem;
  }

  .p-home .c-loading {
    margin: auto;
    min-height: 20rem;
  }

  .p-home .c-loading svg {
    margin: auto;
  }
}
