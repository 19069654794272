@import url(./theme.css);

html, body, #root {
  margin: 0;
  height: 100%;
}

a {
  margin: 0;
  color: var(--primary-color);
  text-decoration: none;
  font-size: 18px;
}

* {
  color: var(--color-text);
  font-family: var(--font-regular);
}

.toastContainer {
  z-index: 1000;
}

.MuiMenu-list {
  max-height: 20rem;
}
