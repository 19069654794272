.c-footer {
  align-items: center;
  bottom: 0;
  display: flex;
  height: 15.625rem;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.c-footer__section {
  display: flex;
  flex-direction: column;
  margin: 5%;
  min-width: fit-content;
}

.c-footer__section:first-of-type {
  margin-left: 10%;
}

.c-footer__section:last-of-type {
  margin-right: 25%;
}

.c-footer__section > a {
  margin: 1.2rem 0;
  min-height: 1.5rem;
  max-height: 1.5rem;
  display: flex;
  align-items: center;
  color: var(--color-default-45);
}

.c-footer__section > a > svg {
  width: 27px;
  height: 27px;
  margin-right: 1rem;
}

@media only screen and (max-width: 1366px) {
  .c-footer__section:first-of-type {
    margin-left: 5%;
  }
}

@media only screen and (max-width: 1100px) {
  .c-footer {
    flex-wrap: wrap;
  }

  .c-footer__section,
  .c-footer__section:first-of-type,
  .c-footer__section:last-of-type {
    margin: 0;
  }
  
  /* Logo */
  .c-footer__section:first-of-type {
    width: 100%;
    align-items: center;
    margin: 3rem 0;
  }

  .c-footer__section {
    margin-left: 5%;
  }

  .c-footer__section:last-of-type {
    margin-right: 5%;
  }
}

@media only screen and (max-width: 600px) {
  .c-footer {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .c-footer__section:last-of-type {
    margin-left: 5%;
  }

  .c-footer__section {
    margin: 0;
    min-width: 100%;
    align-items: center;
  }
  
  .c-footer__section > a {
    font-size: 1rem;
  }

  .c-footer__section-social-media {
    display: flex;
    flex-direction: row;
    place-content: center;
    align-items: center;
    justify-content: space-around;
    min-width: unset;
    align-self: center;
    width: 50%;
  }

  .c-footer__section > a > svg {
    margin: 0;
  }
}
