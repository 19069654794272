.c-price-slider .MuiSlider-rail {
  background-color: #e8e8e8;
  opacity: 1;
}

.c-price-slider .MuiSlider-track {
  opacity: 1;
  border: none;
}

.c-price-slider .MuiSlider-track,
.c-price-slider .MuiSlider-thumb {
  background-color: var(--color-default-45);
}

.c-price-slider .MuiSlider-valueLabel {
  background-color: transparent;
  top: 3.5rem;
  right: -2rem;
}

@media only screen and (max-width: 768px) {
  .c-filter-box__modal-content .c-price-slider {
    width: 85%;
  }
}
