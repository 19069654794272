.c-contact-seller {
  border: 1px solid #DDDDDD;
  border-radius: 0.313rem;
  padding: 0.5rem 2rem;
  height: 30rem;
  min-height: 30rem;
  width: 21.875rem;
  min-width: 21.875rem;
  background-color: #fff;
}

.c-contact-seller__title {
  display: block;
  text-align: center;
  font-size: 1rem;
  color: var(--color-text);
  font-family: var(--font-semi-bold);
  margin: 2rem 0;
}

.c-contact-seller__prices {
  display: flex;
  flex-direction: column;
  height: 8rem;
  justify-content: space-between;
}

.c-contact-seller__prices div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-contact-seller__prices div label:first-child {
  font-size: 0.875rem;
}

.c-contact-seller__hr {
  width: 30%;
  margin-right: 0;
}

.c-contact-seller__total {
  align-self: flex-end;
}

.c-contact-seller__total label {
  font-size: 1rem !important;
  color: var(--color-default-45);
  font-family: var(--font-bold) !important;
}

.c-contact-seller__contact {
  margin: 2rem 0;
  font-size: 0.85rem;
  display: flex;
  flex-direction: column;
  width: fit-content;
  width: 100%;
}

.c-contact-seller__contact .c-button {
  width: fit-content;
  align-self: center;
  margin: 2rem 0;
}

.c-contact-seller__contact-separator {
  display: block;
  align-self: center;
  text-align: center;
  width: 100%;
}

.c-contact-seller__contact-separator:after,
.c-contact-seller__contact-separator:before {
  display: inline-block;
  content: "";
  border-top: 1px solid #DDDDDD;
  width: 43%;
  transform: translateY(-0.188rem);
}

.c-contact-seller__contact-separator:after {
  margin-left: 1rem;
}

.c-contact-seller__contact-separator:before {
  margin-right: 1rem;
}

.c-contact-seller__social-media {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 65%;
  align-self: center;
  margin: 2rem 0;
}

.c-contact-seller__social-media svg {
  width: 1.875rem;
  cursor: pointer;
}

@media only screen and (max-width: 1366px) {
  .c-contact-seller__social-media svg {
    width: 1.7rem;
  }

  .c-contact-seller {
    height: 27.675rem;
    min-height: 27.675rem;
    min-width: 19rem;
    width: 19rem;
  }

  .c-contact-seller__title {
    margin: 1.75rem 0;
  }

  .c-contact-seller__contact {
    margin: 1.75rem 0;
  }

  .c-contact-seller__contact .c-button {
    margin: 1.75rem;
  }

  .c-contact-seller__social-media {
    margin: 1.35rem 0;
  }

  .c-contact-seller__social-media svg {
    width: 1.7rem;
  }

  .c-contact-seller__contact-separator:after,
  .c-contact-seller__contact-separator:before {
    width: 42%;
  }
}

@media only screen and (max-width: 768px) {
  .c-contact-seller {
    position: static;
    margin-top: 2rem;
    width: 100%;
    max-width: 100%;
    padding: 0;
  }

  .c-contact-seller__prices {
    margin: 0 1.5rem;
  }

  .c-contact-seller__contact {
    margin: 1.5rem 1.5rem 0;
    width: unset;
  }

  .c-contact-seller__contact-separator:after,
  .c-contact-seller__contact-separator:before {
    width: 41%;
  }
}
