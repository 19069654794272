.c-selector {
  margin: 0.5rem 0 !important;
}

.c-select__compact {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between;
}

.c-select__compact .MuiInputBase-formControl {
  min-width: calc(100% - 3rem);
}

.c-selector-modal_visibiliy_btn {
  width: 1.5rem;
  fill: var(--primary-color);
  cursor: pointer;
}

.c-selector-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 65%;
  min-width: 65%;
  height: fit-content;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 6px;
  padding: 2rem;
  overflow: auto;
}

.c-selector-modal:focus-visible{
  outline: none;
}

/* .MuiModal-root:before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
} */

@media only screen and (max-width: 768px) {
  .c-selector-modal {
    width: fit-content;
    height: 95%;
    position: sticky;
    transform: unset;
    padding: 1.5rem;
    border-radius: 0;
  }
}
