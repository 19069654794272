.c-info-badge,
.c-info-badge div {
  display: flex;
  align-items: center;
  height: fit-content;
}

.c-info-badge__with-label {
  flex-direction: column;
  align-items: flex-start;
}

.c-info-badge svg {
  margin-right: 0.5rem;
}

.c-info-badge__label {
  font-size: 0.85rem;
}
