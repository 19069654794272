.c-property-list__see-more {
  width: 100%;
  text-align: right;
  color: var(--color-text) !important;
  font-family: var(--font-semi-bold) !important;
  font-size: 0.935rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.c-property-list__see-more:hover,
.c-property-list__see-more:hover svg path {
  font-family: var(--font-bold) !important;
  color: black !important;
  stroke: black;
}

.c-property-list__see-more svg {
  width: 0.625rem;
  height: 0.625rem;
  margin-left: 0.35rem;
}

.c-property-list .c-loading {
  place-self: center;
  margin: auto;
}

.c-property-list__loading {
  height: 10rem;
  display: flex;
}

.c-property-list__loading .c-loading {
  margin: auto;
}

.c-property-list .c-no-content {
  margin: auto;
}

@media only screen and (max-width: 768px) {
  .c-property-list__see-more {
    margin-top: 2rem !important;
  }
}

/* Width */
::-webkit-scrollbar {
  width: 0.65rem;
  height: 0.438rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #dfdfdf;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-default-35);
}
