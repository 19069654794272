.c-property-slider__property {
  width: 70%;
  min-width: 70%;
  position: relative;
}

.c-property-slider__property img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.c-property-slider__box {
  position: absolute;
  z-index: 100;
  bottom: 10%;
  right: 5%;
}

.c-property-slider__steps {
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 100%;
}

.c-property-slider__step {
  border: 1px solid #ddd;
  border-bottom: none;
  height: fit-content;
  max-width: 100%;
  padding: 0.75rem 1rem;
  cursor: pointer;
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  max-height: 7.625rem;
}

.c-property-slider__step:last-of-type {
  border-bottom: 1px solid #ddd;
}

.c-property-slider__box .c-card__vertical .c-badge {
  top: -23rem;
  right: 0rem;
}

.c-property-slider__box .c-card__vertical .c-card-property__content {
  height: 15rem;
}

.c-property-slider__box .c-card-property__line {
  width: 40%;
  margin: 0;
  margin-left: auto;
}

.c-property-slider__box .c-card__vertical .c-card-property__price label {
  font-size: 0.85rem;
}

.c-property-slider__box .c-card-title {
  margin-top: 0.75rem;
}

.c-property-slider__box .c-card-property__address {
  margin-bottom: 0.75rem;
}

.c-property-slider__step-title {
  font-family: var(--font-semi-bold);
  color: var(--color-text);
}

.c-property-slider__step-title,
.c-property-slider__step-address-box {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-property-slider__step-description,
.c-property-slider__step-address {
  color: var(--color-secondary);
  font-size: 0.875rem;
}

.c-property-slider__step-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.c-property-slider__step-address-box {
  display: flex;
  align-items: center;
}

.c-property-slider__step-address-box svg {
  width: 0.75rem;
  min-width: 0.75rem;
  margin-right: 0.5rem;
  fill: var(--color-secondary);
}

.c-property-slider__active {
  border: 2px solid var(--color-default-45) !important;
}

@media only screen and (max-width: 768px) {
  .c-property-slider__property {
    width: 100%;
  }
}
