.c-image-uploader {
  display: flex;
  height: 10rem;
  gap: 1rem;
}

.c-file-uploader__image-preview,
.c-image-uploader__upload-btn {
  width: 8rem;
  height: 8rem;
  border-radius: 5px;
  cursor: pointer;
  display: block;
}

.c-file-uploader__image-preview {
  position: relative;
}

.c-image-uploader__upload-btn:after {
  content: '+';
  color: white;
  font-size: 5rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  line-height: 1.56;
}

.c-image-uploader__upload-btn {
  background-color: var(--primary-color);
  margin: 0;
}

.c-file-uploader__image-preview:first-of-type {
  margin-left: 0;
}

.c-file-uploader__image-preview img {
  border-radius: 5px;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.c-file-uploader__image-preview__delete {
  width: 1.15rem;
  display: none;
  height: 1.15rem;
  background-color: rgba(0, 0, 0, 0.679);
  position: absolute;
  top: -0.3rem;
  right: -0.3rem;
  border-radius: 50%;
}

.c-file-uploader__image-preview__delete:after {
  content: '+';
  color: white;
  font-size: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  line-height: 1.2;
  transform: rotate(45deg);
}

.c-file-uploader__image-preview:hover {
  height: 10rem;
}

.c-file-uploader__image-preview:hover img {
  height: 8rem;
}

.c-file-uploader__image-preview:hover .c-file-uploader__image-preview__delete {
  display: block;
}

.c-image-uploader__main-image-box {
  display: none;
  font-size: 0.875rem;
}

.c-image-uploader__main-image-box span {
  padding: 0;
  margin-right: 0.35rem;
}

.c-image-uploader__main-image-box span svg {
  width: 1.25rem;
}

.c-image-uploader__main-image {
  display: flex;
}

.c-file-uploader__image-preview:hover .c-image-uploader__main-image-box {
  display: flex;
}

@media only screen and (max-width: 1366px) {
  .c-file-uploader__image-preview,
  .c-image-uploader__upload-btn {
    width: 6rem;
    height: 6rem;
    margin: 0;
  }

  .c-file-uploader__image-preview .banner-image {
    padding-bottom: 2rem !important;
    padding-bottom: 2rem;
  }

  .c-file-uploader__image-preview:hover {
    padding-bottom: 2rem;
  }

  .c-image-uploader__upload-btn:after {
    line-height: 1.135;
  }
}

@media only screen and (max-width: 768px) {
  .c-image-uploader {
    display: flex;
    flex-flow: wrap;
  }

  .banner-image {
    padding-bottom: 2rem !important;
    padding-bottom: 2rem;
  }

  .c-image-uploader__upload-btn {
    margin-top: 0.5rem;
  }

  .c-image-uploader .c-image-uploader__upload-btn {
    margin: 0;
  }
}

