.c-table__row-even {
  background-color: #FFFFFF;
}

.c-table__row-odd,
.c-table__cell-header {
  background-color: #F9F9F9;
}

.c-table__cell-header {
  cursor: default;
}

.c-table__row .MuiTableCell-root {
  font-size: 0.765rem;
}

.c-table .c-loading {
  height: 50px;
  left: calc(50% - 2rem);
}

.c-table .c-loading,
.c-table .c-table__no-content {
  position: absolute;
  top: 38%;
}

.c-table .c-table__no-content {
  width: 12rem;
  left: calc(50% - 6rem);
}

@media only screen and (max-width: 1366px) {
  .c-table .c-loading,
  .c-table .c-table__no-content {
    position: absolute;
    top: 50%;
  } 
}
