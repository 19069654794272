.p-properties {
  justify-content: space-between;
  min-height: calc(100vh - 10rem);
}

.p-properties__list {
  width: 72%;
  display: flex;
  justify-content: right;
  margin-left: auto;
}

.p-properties__list .c-property-list {
  width: 98%;
}

.p-properties .c-filter-box {
  height: fit-content;
}

.p-properties__list .c-card-property img {
  max-width: 17rem;
  min-width: 17rem;
  object-fit: cover;
}

.p-properties__list .c-loading,
.p-properties__list .c-property-list .c-no-content {
  margin: auto;
  margin-top: 22%;
  width: fit-content;
}

@media only screen and (max-width: 1366px) {
  .p-properties > .c-filter-box {
    min-width: 25rem;
  }

  .p-properties__list .c-loading,
  .p-properties__list .c-property-list .c-no-content {
    margin-top: 32.5%;
  }

  .p-properties .c-filter-box__form {
    min-width: 20rem;
    padding: 0.5rem 1.5rem;
  }

  .p-properties .c-property-list {
    width: 100%;
  }

  .p-properties__list {
    width: 65%;
    margin-left: auto;
  }

  .p-properties .c-property-list {
    width: 100%;
    padding-right: 1rem;
  }

  .p-properties {
    min-height: calc(100vh - 10rem);
  }

  .p-properties .c-filter-box {
    width: 25rem;
    min-width: 25rem;
    position: relative;
    height: fit-content;
  }

  .p-properties .c-card-property {
    height: 11rem;
  }

  .p-properties .c-card-property .c-card-property__title {
    font-size: 1rem;
  }

  .p-properties .c-card-property .c-card-property__description {
    font-size: 0.825rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .p-properties .c-card-property .c-info-badge {
    font-size: 0.825rem;
  }
  
  .p-properties .c-card-property .c-info-badge svg {
    width: 1.375rem;
  }

  .p-properties .c-card-property .c-card-property__address .c-info-badge svg {
    width: 0.675rem;
  }
}

@media only screen and (max-width: 768px) {
  .c-filter-box {
    min-width: unset;
    width: unset;
  }

  .p-properties .c-filter-box {
    width: 20rem;
    min-width: 20rem;
    height: fit-content;
  }
  
  .p-properties.c-filter-box {
    width: 20rem;
    min-width: 20rem;
  }

  .p-properties > .c-filter-box,
  .p-properties .c-property-list {
    width: 100%;
    padding-right: 0;
  }

  .c-filter-box__header {
    margin: 1rem 0 1rem;
  }

  .c-filter-box__header .c-filter-box__title {
    text-align: center;
    font-size: 1rem;
  }

  .c-filter-box__advice {
    font-size: 0.7rem;
    margin: 0.75rem 0 1.25rem;
  }

  .c-filter-box__slider-price {
    margin-top: 0.75rem;
  }

  .p-properties__list {
    width: 100%;
  }

  .p-properties__list .c-no-content {
    margin-top: 70% !important;
  }

  .p-properties__list .c-card-property img {
    max-width: 35%;
    object-fit: cover;
  }

  .p-properties .c-card-property {
    height: 8rem;
    border-radius: 5px;
  }

  .p-properties .c-card-property img {
    border-radius: 5px 0 0 5px;
  }

  .p-properties .c-card-property__content {
    width: 100%;
    overflow: hidden;
    padding: 0.65rem 1rem;
  }
  
  .p-properties .c-card-property__button-section .c-button {
    display: none;
  }

  .p-properties .c-card-property .c-info-badge,
  .c-property-list .c-card-property__price {
    font-size: 0.75rem;
  }

  .p-properties .c-card-property .c-card-property__title {
    font-size: 0.835rem;
    max-width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .p-properties .c-card-property .c-info-badge svg {
    width: 1rem;
  }

  .p-properties .c-card-property__button-section {
    position: absolute;
    padding: 0;
    padding-top: 0.5rem;
    right: 2rem;
    width: fit-content;
    min-width: unset;
  }
    
  .p-properties .c-card-property__button-section .c-badge {
    font-size: 0.85rem;
    padding: 0.2rem 0.8rem;
  }

  .p-properties .c-loading {
    position: absolute;
    top: 30%;
    left: calc(50% - 1rem);
  }

  .p-properties__list .c-card-property img {
    max-width: 8rem;
    min-width: 8rem;
  }
}
