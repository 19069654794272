.c-badge {
  border-radius: 20px;
  width: fit-content;
  padding: 0.3rem 1.2rem;
}

.c-badge-rent {
  background-color: #F8E74F;
}

.c-badge-sell {
  background-color: #89D790;
}
