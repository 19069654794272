.c-file-uploader {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.c-file-uploader__file-preview,
.c-file-uploader__upload-btn {
  max-width: 50%;
  height: 4rem;
  border-radius: 5px;
  cursor: pointer;
  display: block;
}

.c-file-uploader__file-preview {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 45%;
  min-width: 45%;
  height: 2rem;
}

.c-file-uploader__file-preview a {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-file-uploader__file-preview svg {
  width: 2rem;
  min-width: 2rem;
  margin-right: 0.5rem;
}

.c-file-uploader__file-name {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-file-uploader__upload-btn:after {
  content: '+';
  color: white;
  font-size: 2.5rem;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  line-height: 1.56;
}

.c-file-uploader__upload-btn {
  background-color: var(--primary-color);
}

.c-file-uploader__file-preview img {
  border-radius: 5px;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.c-file-uploader__file-preview__delete {
  display: none;
  height: 100%;
}

.c-file-uploader__file-preview__delete:after {
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.679);
  border-radius: 50%;
  color: white;
  content: '+';
  display: flex;
  font-size: 1rem;
  height: 1.15rem;
  justify-content: center;
  line-height: 1.2;
  transform: rotate(45deg);
  width: 1.15rem;
}

.c-file-uploader__file-preview:hover .c-file-uploader__file-preview__delete {
  display: block;
}

.c-file-uploader__main-file-box {
  display: none;
  font-size: 0.875rem;
}

.c-file-uploader__main-file-box span {
  padding: 0;
  margin-right: 0.35rem;
}

.c-file-uploader__main-file-box span svg {
  width: 1.25rem;
}

.c-file-uploader__main-file {
  display: flex;
}

.c-file-uploader__file-preview:hover .c-file-uploader__main-file-box {
  display: flex;
}

@media only screen and (max-width: 768px) {
  .c-file-uploader__file-preview a {
    font-size: 1rem;

  }
  
  .c-file-uploader__file-preview svg {
    width: 1.5rem;
    min-width: 1.5rem;
  }

  .c-file-uploader__file-preview, .c-file-uploader__upload-btn {
    height: 2.5rem;
  }
  .c-file-uploader__upload-btn:after {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 2rem;
    line-height: 1.2;
  }
}
