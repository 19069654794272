.c-filter-box {
  width: 27%;
  min-width: 27rem;
}

.c-filter-box__form {
  border: 1px solid #DDDDDD;
  height: fit-content;
  min-height: 30rem;
  border-radius: 5px;
  padding: 0.5rem 2rem;
}

.c-filter-box__fields {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}

.c-filter-box__fields .c-input {
  margin: 0.35rem 0;
  width: 48.5%;
}

.c-filter-box__fields .c-input:first-of-type {
  width: 100%;
}

.c-filter-box__combo-box .c-combo-box {
  margin: 0.5rem 0;
  align-items: center;
}

.c-filter-box__advice {
  font-size: 0.8125rem;
  display: block;
  margin: 0.75rem 0 1.75rem;
}

.c-filter-box__advice::first-letter {
  color: var(--color-advice-red);
}

/* c-filter-box__header */
.c-filter-box__header .c-filter-box__title {
  text-align: center;
  margin: 2rem 0 1.75rem;
}

.c-filter-box__button {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 3rem 0 2rem;
}

.c-filter-box__slider-price {
  margin-top: 1.75rem;
}

.c-filter-box__slider-price .c-price-slider {
  margin-top: 0.75rem;
  width: 92%;
}

.p-properties .c-filter-box__slider-price,
.c-filter-box__modal-content .c-filter-box__slider-price {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .c-filter-box {
    position: fixed;
    width: 25%;
    min-width: 28rem;
    height: fit-content;
  }

  .c-filter-box__modal-content {
    background-color: white;
    min-height: 100%;
  }
  
  .c-filter-box__modal-content .c-filter-box__form {
    padding: 0.5rem 1.5rem;
  }

  .c-filter-box__modal-content .c-filter-box__form {
    border: none;
  }

  .c-filter-box__modal-content .c-filter-box__header {
    display: flex;
    justify-content: space-between;
  }

  .c-filter-box__modal-content .c-filter-box__header .c-filter-box__title {
    margin: 0;
  }

  .c-filter-box__modal-content .c-filter-box__header svg {
    width: 1rem;
    height: 1rem;
  }

  .c-filter-box__show-filter-btn {
    margin-bottom: 1.5rem !important;
  }
}
