.c-navbar {
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  height: 5rem;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  z-index: 1000;
}

.c-navbar__logo {
  max-width: 100%;
  max-height: 70%;
  margin: 0rem 2rem;
}

.c-navbar__content {
  display: flex;
  justify-content: end;
  overflow: auto;
  white-space: nowrap;
  width: 100%;
  align-items: center;
}

.c-navbar__content > * {
  margin: 0 2rem !important;          /* overrides MUI style*/
}

.c-navbar__content a {
  font-size: 0.875rem;
  text-transform: uppercase; 
}

.c-navbar__drawer-navbar {
  display: flex;
  height: 3rem;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem 0;
}

.c-navbar__drawer {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 80%;
  z-index: 1000;
  padding-top: 1rem;
  animation: slide-left-forwards 0.30s cubic-bezier(1, 1, 0, -0.01) both;
}

.c-navbar__drawer a {
  text-transform: uppercase;
  font-size: 0.875rem;
}

.c-navbar__drawer-navbar a {
  margin: 0.75rem 2rem;
}

.c-navbar__drawer.c-navbar__drawer-closing {
  animation: slide-left-backwards 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.navbar-logout-link {
  color: #d32f2f;
}

@keyframes slide-left-forwards {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-left-backwards {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@media only screen and (max-width: 600px) {
  .c-navbar__logo {
    margin: 0rem;
    max-height: 85%;
  }
}
