.c-client-table__table-wrapper {
  align-items: center;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.c-client-table__table-wrapper .c-pagination {
  margin-top: 3rem;
}

.c-client-table__table-wrapper .c-table {
  max-width: 96%;
  /* min-height: 30rem; */
  border: 1px solid #f7f7f7;
  box-shadow: none;
}

.c-client-table__table-wrapper .MuiTableCell-sizeMedium {
  line-height: 2rem;
}

.c-client-table__content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 46.5rem;
}

.c-client-table__content .c-table__popover,
.c-client-table__content .c-client-table__add {
  width: 1.15rem;
  min-width: 1.15rem;
  fill: var(--primary-color);
  cursor: pointer;
  margin-top: 1rem;
}

.c-client-table__add {
  width: 1.5rem !important;
  margin-top: 1.25rem !important;
}

.c-client-table__content .c-table__popover .MuiTableCell-body:last-of-type {
  min-width: unset;
  padding: 0;
  width: 1rem;
}

.c-client-table__content .MuiTableCell-body {
  cursor: default;
}

.c-client-table__content .MuiTableCell-body:first-of-type {
  cursor: pointer;
}

.c-client-table__content .c-table__popover .MuiTableCell-head:last-of-type {
  min-width: unset;
  padding: 0;
  width: 1rem;
}

.c-client-table__popover-options {
  width: 8rem;
}

.c-client-table__popover-options button {
  cursor: pointer;
  width: 8rem;
  padding: 0.85rem 1rem;
  justify-content: inherit;
  color: black;
  text-transform: capitalize;
}

.c-client-table__popover-options button:last-of-type {
  color: red;
  font-family: var(--font-semi-bold);
}

.c-client-table__popover-options p {
  padding: 0.85rem 1rem;
  font-size: 0.875rem;
}

@media only screen and (max-width: 1366px) {
  .c-client-table__content {
    min-height: 34.5rem;
  }

  .c-client-table__table-wrapper .MuiTableCell-sizeMedium {
    line-height: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 14rem;
  }

  .c-client-table__table-wrapper .c-pagination {
    margin-top: 1rem;
  }

  .c-client-table__content .c-table__popover {
    margin-top: 5px;
  }
}

@media only screen and (max-width: 768px) {
  .c-client-table__content {
    min-height: 45rem;
  }

  .c-client-table__table-wrapper .MuiTableCell-sizeMedium {
    line-height: 2rem;
    min-width: 7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 7rem;
  }

  .c-client-table__table-wrapper .MuiTableCell-sizeMedium:last-of-type {
    max-width: 0.125rem;
    min-width: 0.125rem;
  }

  .c-client-table__table-wrapper .c-table {
    height: 100%;
  }
}
