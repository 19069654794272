.c-login {
  align-items: center;
  display: flex;
  height: 100%;
  height: 28rem;
  margin: auto;
  min-width: 20rem;
  width: 20%;
}

.c-login__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--color-border-input);
  border-radius: 5px;
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c-login__logo {
  width: 8rem;
  height: auto;
}

.c-login .c-form-layout__section {
  width: 100%;
}

.c-login form {
  margin-top: 2rem;
}

.c-login__form .c-form__form-actions-margin {
  margin-top: 2rem;
}

@media only screen and (max-width: 1366px) {
  .c-login {
    width: 32%;
    
  }
}

@media only screen and (max-width: 768px) {
  .c-login {
    padding: 1rem;
    border: none;
  }
}
